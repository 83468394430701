import React from 'react'
import SidePanel from './../../components/Dashboard/SidePanel';

function Headers() {
  return (
    <>
        <SidePanel/>
        <h1>HEaders</h1>
    </>
  )
}

export default Headers